import * as React from 'react'
import styled from '../../styles/styled'
import { Maybe, ProjectsQuery } from '../../graphql-types'
import LocalizedLink from '../translations/LocalizedLink'
import FactItem, { FactItemType, StyledFactContent } from './FactItem'
import { useTheme } from 'emotion-theming'
import { Theme } from '../layouts/LayoutRoot'
import { useTranslation } from 'react-i18next'

interface Props {
  data: Maybe<ProjectsQuery['allMdx']['edges'][0]['node']>
  map: string
}

export type KeyFactItems = 'country' | 'climate' | 'keywords' | 'region' | 'coordinates'

const keyFactItems: KeyFactItems[] = ['country', 'climate', 'keywords']

const TileWrap = styled.div`
  padding: 15px;
  width: calc(100% / 1);
  @media (min-width: ${props => props.theme.breakpoints.md}px) {
    width: calc(100% / 2);
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    width: calc(100% / 3);
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}px) {
    width: calc(100% / 4);
  }
  @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
    width: calc(100% / 5);
  }
`

const TileContainer = styled.div`
  height: 100%;
  border: 1px solid ${props => props.theme.colors.boxborder};
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.mainbg};
`

const KeyFactsContainer = styled.div`
  border-top: 1px solid ${props => props.theme.colors.pink};
  height: calc(100% - 200px);
  display: flex;
  flex-direction: row;
  padding: 15px 5px 0 20px;
  align-items: flex-start;
`

const LinkContainer = styled.div`
  flex: 0 0 auto;
  text-align: right;
  align-self: flex-end;
`

const Icon = styled.div`
  color: ${props => props.theme.colors.pink};
  margin: 0 0 5px 0;
  font-size: ${props => props.theme.dimensions.fontSize.enormous}px;
  svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
`

const StyledLocalizedLink = styled(LocalizedLink)`
  height: 100%;
  display: block;
`

const DataContainer = styled.div`
  flex: 1 1 auto;
`

const StyledTitle = styled.h2`
  color: ${props => props.theme.colors.white};
  margin-top: 0;
  text-transform: upperCase;
`

const StyledTileMapContainer = styled.div`
  height: 200px;
  padding: 15px 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  * {
    position: relative;
    z-index: 10;
  }
`
const PreviewMap = styled.div`
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
`

const Tile: React.FC<Props> = ({ data, map }: Props) => {
  const { t } = useTranslation()

  const { keyFacts } = data!.frontmatter!
  const to = data!.parent!.relativeDirectory!
  const theme = useTheme<Theme>()

  return (
    <TileWrap>
      <StyledLocalizedLink to={to}>
        <TileContainer>
          <StyledTileMapContainer>
            <StyledTitle>{keyFacts!.country!}</StyledTitle>
            <StyledFactContent color={theme.colors.lime} type={FactItemType.list}>
              {keyFacts!.coordinates!}
            </StyledFactContent>
            <PreviewMap>
              <img src={map} alt="" />
            </PreviewMap>
          </StyledTileMapContainer>

          <KeyFactsContainer>
            <DataContainer>
              {keyFactItems.map(keyword => (
                <FactItem
                  key={keyword}
                  title={t(`facts.keywords.${keyword}`)}
                  content={keyFacts![keyword] || ''}
                  type={FactItemType.list}
                />
              ))}
            </DataContainer>

            <LinkContainer>
              <Icon>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  {/* eslint-disable-next-line max-len */}
                  <path d="M13.25 10l-7.141-7.42c-0.268-0.27-0.268-0.707 0-0.979 0.268-0.27 0.701-0.27 0.969 0l7.83 7.908c0.268 0.271 0.268 0.709 0 0.979l-7.83 7.908c-0.268 0.271-0.701 0.27-0.969 0s-0.268-0.707 0-0.979l7.141-7.417z" />
                </svg>
              </Icon>
            </LinkContainer>
          </KeyFactsContainer>
        </TileContainer>
      </StyledLocalizedLink>
    </TileWrap>
  )
}

export default Tile
