import * as React from 'react'
import styled from '../../styles/styled'
import { css } from '@emotion/core'

type Checked = { checked: boolean }

interface ToggleProps {
  stateA?: string | null
  stateB?: string | null
  checked: boolean | null
  toggle: () => void
}

const sliderWidth = '80px'
const toggleWidth = '160px'

const Container = styled.div`
  display: flex;
`

const Label = styled.label`
  position: relative;
  border-radius: 7px;
  align-items: center;
  display: flex;
  width: ${toggleWidth};
  height: 34px;
  @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
    font-size: 17px;
  }
`

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked {
    Slider &:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    & Slider {
      background-color: #2196f3;
    }
  }
  :focus & Slider {
    box-shadow: 0 0 1px #2196f3;
  }
`

const dynamics = (props: Checked) => {
  return props.checked
    ? css`
        -webkit-transform: translateX(${sliderWidth});
        -ms-transform: translateX(${sliderWidth});
        transform: translateX(${sliderWidth});
      `
    : ``
}

const Slider = styled.span<Checked>`
  position: absolute;
  border-radius: 7px;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.slider.gray};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    border-radius: 7px;
    content: '';
    height: 34px;
    width: ${sliderWidth};
    left: 0;
    bottom: 0;
    background-color: ${props => props.theme.colors.slider.blue};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    ${dynamics}
  }
`

const Text = styled.div`
  display: block;
  cursor: pointer;
  text-align: center;
  width: 50%;
  text-transform: upperCase;
  z-index: 100;
`

const ToggleSwitch: React.FC<ToggleProps> = ({ checked, toggle, stateA, stateB }: ToggleProps) => {
  return (
    <Container>
      <Label
        onClick={e => {
          e.preventDefault()
          toggle()
        }}
      >
        <Text>{stateA}</Text>
        <Text>{stateB}</Text>
        <Checkbox type="checkbox" />
        <Slider checked={!!checked} />
      </Label>
    </Container>
  )
}

export default ToggleSwitch
