import * as React from 'react'
import { useEffect, useState } from 'react'

import Page from '../components/layouts/Page'
import { ProjectsQuery } from '../graphql-types'
import Tile from '../components/projects/Tile'
import styled from '../styles/styled'
import { graphql } from 'gatsby'
import ToggleSwitch from '../components/projects/ToggleSwitch'
import { useTranslation } from 'react-i18next'

interface Props {
  data: ProjectsQuery
}

interface ViewTypes {
  tile: string
  map: string
}

const projectViewType: ViewTypes = {
  tile: 'tile',
  map: 'map'
}

const ToggleContainer = styled.div`
  flex: 0 0 100%;
  z-index: 100;
  height: 95px;
`

const TilesContainer = styled.div`
  flex: 0 0 100%;
  z-index: 100;
  padding-bottom: 80px;
`
const TilesContainerInner = styled.div`
  display: flex;
  margin: 0 -20px;
  align-items: stretch;
  flex-wrap: wrap;
`
const ProjektRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 ${props => props.theme.dimensions.containerPadding}rem;
  align-content: flex-start;
`

const Label = styled.div`
  display: block;
  text-transform: upperCase;
  margin-bottom: 10px;
`

const ProjectsPage: React.FC<Props> = ({ data }: Props) => {
  const { edges } = data.allMdx!
  const { t } = useTranslation()

  const [viewType, setViewType] = useState<string | null>(null)

  // initialize tileViewState
  useEffect(() => {
    const initialViewType = localStorage.getItem('projectViewType') || projectViewType.map
    setViewType(initialViewType)
  }, [])

  // persist tileViewState
  useEffect(() => {
    if (localStorage) {
      localStorage.setItem('projectViewType', viewType ? viewType : projectViewType.map)
    }
  }, [viewType])

  const isViewTypeSet = viewType !== null
  const isViewTypeTile = viewType === projectViewType.tile

  const findMap = (project: string): string => {
    const resultNode = data.maps.nodes.filter(node => node.relativePath.includes(project))

    return resultNode.length === 1 && resultNode[0].publicURL ? resultNode[0].publicURL : ''
  }

  return (
    <Page>
      <ProjektRoot>
        {isViewTypeSet && (
          <ToggleContainer>
            <Label>{t(`viewToggle.title`)}:</Label>
            <ToggleSwitch
              stateA={t(`viewToggle.map`)}
              stateB={t(`viewToggle.tile`)}
              checked={isViewTypeTile}
              toggle={() => {
                setViewType(isViewTypeTile ? projectViewType.map : projectViewType.tile)
              }}
            />
          </ToggleContainer>
        )}
        <TilesContainer>
          {isViewTypeTile && (
            <TilesContainerInner>
              {edges!.map(({ node }) => (
                <Tile key={node.id} data={node} map={findMap(node.fields?.project ?? '')} />
              ))}
            </TilesContainerInner>
          )}
        </TilesContainer>
      </ProjektRoot>
    </Page>
  )
}

export const query = graphql`
  query Projects($locale: String) {
    maps: allFile(filter: { extension: { eq: "png" }, name: { eq: "tile-bg-map" } }) {
      nodes {
        publicURL
        name
        relativePath
      }
    }
    allMdx(
      filter: {
        frontmatter: { template: { eq: "project-info" } }
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "/info/" }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            keyFacts {
              climate
              coordinates
              country
              keywords
              name
              region
            }
          }
          fields {
            locale
            isDefault
            project
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
  }
`

export default ProjectsPage
